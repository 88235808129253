<template>
  <div class="one-box flex flex-d" style="position: relative;">
    <Header :times="data.time"></Header>
    <Subhead size="large"></Subhead>
    <div class="main flex flex-d flex-1 ai-c">
      <TitleAndTime :isShwoIcon="false" :time="dateTime">
        <img src="@/assets/speaial_activity_img/image/money_title.png" class="money-title" alt="">
      </TitleAndTime>
      <div class="money">
        <Counter :num="data.sales"></Counter>
      </div>
      <div class="sub-money flex jc-sb">
        <!-- 包裹数 -->
        <div class="sub-money-item flex flex-d ai-c jc-sb">
          <img src="@/assets/speaial_activity_img/image/sub_money_number_title.png" alt="">
          <CounterStyleMin :num="data.packageNum"></CounterStyleMin>
        </div>
        <!-- 平均价 -->
        <div class="sub-money-item flex flex-d ai-c jc-sb">
          <img src="@/assets/speaial_activity_img/image/sub_money_title.png" alt="">
          <CounterStyleMin :num="data.pct"></CounterStyleMin>
        </div>
      </div>
    </div>
    <Annotation></Annotation>
  </div>
</template>

<script>
import Header from '@/components/Big/Header.vue'
import TitleAndTime from '@/components/Big/TitleAndTime'
import Annotation from '@/components/Big/Annotation'
import Subhead from '@/components/Big/Subhead'
import Counter from '@/components/Big/Counter.vue'
import CounterStyleMin from '@/components/Big/CounterStyleMin.vue'
export default {
  components: {
    Header,
    Counter,
    CounterStyleMin,
    Annotation,
    TitleAndTime,
    Subhead,
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          sales: '',
          packageNum: '',
          pct: '',
        }
      },
    },
  },
  watch: {
    data: {
      handler(val) {
        // console.log('楼层1 ZN', val)
        if (Object.keys(val).length != 0) {
          this.dateTime = val.time + '数据时段' || ''
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      saleNum: '',
      headTime: '',
      dateTime: '',
    }
  },
  mounted() {},
  methods: {
    init() {},
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/rem.scss';
@import './style/defaultZn.scss';
.one-box {
  width: 100%;
  height: 100%;
  font-size: rem(24);
  background: url('../../../../assets/speaial_activity_img/image/one_bg.png')
    no-repeat center;
  background-size: 100% 100%;
  overflow: hidden;
}
</style>
